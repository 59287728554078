<template>
  <img src="../assets/img01.jpeg"/>  
  <div class="container">
    <h1>Redirect to temporal site...</h1>
  </div>
</template>

<script>
setTimeout(function(){location.href="https://lauracrodriguezv.wixsite.com/remmitoheredado"} , 3000);
export default {

}
</script>

<style scoped>

img {
    /* Set rules to fill background */
  min-height: 100%;
  min-width: 700px;
	
  /* Set up proportionate scaling */
  width: 100%;
  height: auto;
	
  /* Set up positioning */
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.titulo {
  margin-left: 100px;
}

a {
  color: red;
  background-color: blue;
  padding-left:100px;
}

.container {
  position: absolute;
  width: 100%;
  text-align: center;
  line-height: 309px;
}
</style>