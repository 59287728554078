<template>
<div class="navbar">
  <ul>
    <li><a href="#">Team</a></li>
    <li><a href="#">Gallery</a></li>
    <li><a href="#">Characters</a></li>
    <li><a href="#">History</a></li>
    <li><a href="#">Gameplay</a></li>
    <li><a href="#">Home</a></li>
</ul>
</div>
</template>

<script>
export default {
    name: "Nav",
    props: {}
}
</script>

<style scoped>
* {
    margin: 0;
    padding: 0;
}

ul {
    list-style-type: none;
    overflow: hidden;
    background-color: #333;
}

li {
    float: right;
}

li a {
    display: block;
    color: white;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
}

li a:hover {
    background-color: #111;
}

.navbar {
    position: relative;
}
</style>