<template>
  <!-- <img alt="Vue logo" src="./assets/logo.png"> -->
  <Nav/>
  <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
  <Intro/>
</template>

<script>
//import HelloWorld from './components/HelloWorld.vue'
import Nav from './components/Nav.vue'
import Intro from './components/Intro.vue'

export default {
  name: 'App',
  components: {
    //HelloWorld,
    Nav,
    Intro
  }
}
</script>

<style>
* {
  margin: 0;
  padding: 0;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white;
}
</style>
